/**
 * bac.js
 */

(function ($) {
    $(document).ready(function () {
        let now = new Date();
        const weight = document.getElementById('weight');
        let baseDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 8, 0, 0, 0);
        let currentTimeHours = (now - baseDate) / 3600000;
        currentTimeHours = Math.floor(currentTimeHours * 2) / 2; // Round to the nearest half-hour.
        let eightHoursBefore = Math.max(0, currentTimeHours - 8);

        $('#gender').on("click", "button", function () {
            $("#gender button").removeClass("active disabled");
            $(this).toggleClass("active");
            $('.buttons button').removeAttr('disabled');
            $('#gender-select').removeClass('active');
            if (weight) {
                weight.removeAttribute('disabled');
            }
            calculateBac(baseDate);
        });

        // Function to format time in 24-hour format with day indication
        function formatTime(hours, baseDate) {
            let date = new Date(baseDate.getTime() + hours * 3600000);
            let dayLabel;

            // Determine the correct day label
            if (hours < 16) {
                dayLabel = bac_object.yesterday;
            } else if (hours < 40) {
                dayLabel = bac_object.today;
            } else {
                dayLabel = bac_object.tomorrow;
            }

            let hoursPart = date.getHours();
            let minutesPart = date.getMinutes();
            minutesPart = minutesPart < 10 ? '0' + minutesPart : minutesPart;
            return dayLabel + " " + hoursPart + ':' + minutesPart;
        }

        // Function to calculate time difference
        function calculateTimeDifference(start, end) {
            let diff = end - start;
            let hours = Math.floor(diff);
            let minutes = Math.round((diff % 1) * 60);
            return hours + bac_object.hours + ' ' + (minutes > 0 ? (minutes + bac_object.minutes) : '');
        }

        // Populate select elements
        function populateSelect(select, selectedValue) {
            select.empty();  // Clear existing options if any.
            for (let i = 0; i <= 48; i += 0.5) {
                let option = $('<option></option>').val(i).text(formatTime(i, baseDate));
                if (Math.abs(i - selectedValue) < 0.25) {  // Condition to set selected based on rounding.
                    option.prop('selected', true);
                }
                select.append(option);
            }
        }

        // Populate start-time and end-time selects
        populateSelect($('#start-time'), eightHoursBefore);
        populateSelect($('#end-time'), currentTimeHours);

        // Update time difference and other UI elements on change
        $('#start-time, #end-time').on('change', function () {
            let startTime = parseFloat($('#start-time').val());
            let endTime = parseFloat($('#end-time').val());
            if (startTime > endTime) {
                endTime = startTime;
                $('#end-time').val(endTime);
            }
            $('#time-difference').text(calculateTimeDifference(startTime, endTime));
            calculateBac(baseDate);
        });
        $('#time-difference').text(calculateTimeDifference(eightHoursBefore, currentTimeHours));

        $('#bac-counter').on('click', '.plus, .minus', function () {
            handleConsumptionClick.call(this, $(this).hasClass('plus') ? 1 : -0.5);
            // following row added for servings
            calculateAllDrinkServings();
        });

        // Calculate custom drink servings when custom drink select value changes
        $(document).on('input', '.custom-percent, .size', function () {
            calculateAllDrinkServings();
            calculateBac(baseDate);
        });

        function handleConsumptionClick(increment) {
            let amount = $(this).closest('.alcohol').find('.amount');
            let value = parseFloat(amount.text());
            if (!value) {
                value = 0;
            }
            value += increment;
            if (value < 0) {
                value = 0;
            }
            amount.text(value.toFixed(1));
            calculateBac(baseDate);
        }

        if (weight) {
            weight.addEventListener('input', function () {
                calculateBac(baseDate);
            });
        }

        $('#bac-counter #duplicate').on('click', function () {
            let customAlcohol = $('.custom').first();
            let newCustomAlcohol = customAlcohol.clone();
            newCustomAlcohol.find('.amount').text(0);
            $('.alcohols').children('.custom').last().after(newCustomAlcohol);
        });

        calculateAllDrinkServings();
    });

    // Calculate BAC (Blood Alcohol Content)
    function calculateBac(baseDate) {

        let bodyWeightKg = parseFloat($('#weight').val());
        const genderMultiplier = parseFloat($("#gender button.active").data('multiplier'));
        const bodyFluids = bodyWeightKg * genderMultiplier;
        const time = parseInt($('#time-difference').text().split('h')[0]);
        let alcoholGrams = alcoholServings() * 12; // assuming alcoholServings() returns the number of servings
        const burnRate = (bodyWeightKg / 10) * time;

        if (alcoholGrams < 0) {
            alcoholGrams = 0;
        }

        alcoholGrams = Math.max(0, alcoholGrams - burnRate);
        const bac = (alcoholGrams / bodyFluids);
        const timeToSober = alcoholGrams / ((bodyWeightKg * genderMultiplier) / 10);

        // Convert timeToSober to hours and minutes
        const hoursToSober = Math.floor(timeToSober);
        const minutesToSober = Math.round((timeToSober % 1) * 60);

        // Retrieve the selected end time
        let endTimeHours = parseFloat($('#end-time').val());
        let endTime = new Date(baseDate.getTime() + endTimeHours * 3600000); // Calculate endTime based on baseDate

        // Add timeToSober to endTime
        endTime.setHours(endTime.getHours() + hoursToSober);
        endTime.setMinutes(endTime.getMinutes() + minutesToSober);

        // Format the future sober time string
        let soberTimeString = endTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: false });

        $('#bac-result').attr('class', '').addClass(getBacColor(bac));
        $('#bac-result').text(bac_object.bac_level + ': ' + bac.toFixed(1) + ' ‰');
        $('#bac-warning').html('<span class="time-to-sober">' + bac_object.bac_time_to_sober + ': ' + hoursToSober + 'h ' + minutesToSober + 'm (' + soberTimeString + ' ' + bac_object.bac_until +')</span>' + getBacWarning(bac));
    }

    function getBacColor(bac) {
        if (bac < 0.1) {
            return 'green';
        } else if (bac < 0.55) {
            return 'yellow';
        } else if (bac < 0.95) {
            return 'orange';
        } else {
            return 'red';
        }
    }

    function getBacWarning(bac) {
        if (bac < 0.1) {
            return bac_object.bac_warning_1;
        } else if (bac < 0.55) {
            return bac_object.bac_warning_2;
        } else if (bac < 0.95) {
            return bac_object.bac_warning_3;
        } else if (bac < 1.95) {
            return bac_object.bac_warning_4;
        } else if (bac < 2.95) {
            return bac_object.bac_warning_5;
        } else if (bac < 5.95) {
            return bac_object.bac_warning_6;
        } else {
            return bac_object.bac_warning_7;
        }
    }

    function alcoholServings() {

        let alcoholServings = 0;
        $('#bac-counter .alcohols .alcohol').each(function (index, element) {
            const serving = parseFloat(element.dataset.serving);
            const amount = $(this).find('.amount').text() ? parseFloat($(this).find('.amount').text()) : 0;
            // Skip calculation if amount is less than 0.5
            if (amount < 0.5) {
                return; // Skip this iteration
            }
            let alcoholServing = serving * amount;
            alcoholServings += alcoholServing;
        });
        return alcoholServings;
    }

    function calculateAllDrinkServings() {
        let allServings = 0;
        $('.alcohol.custom').each(function() {
            $(this).attr('data-percentage', parseFloat($(this).find('.custom-percent').val()));
        });
        $('.alcohol').each(function() {
            let $alcoholElement = $(this);
            let percent = parseFloat($alcoholElement.attr('data-percentage'));
            let size = parseFloat($alcoholElement.find('select.size').val());
            let sizeInLiters = size / 100;
            let pureAlcoholInGrams = sizeInLiters * (percent / 100) * 789; // 789g/L is the density of ethanol
            let servings = pureAlcoholInGrams / 12;
            $alcoholElement.attr('data-serving', servings.toFixed(2));
            let calories = pureAlcoholInGrams * 7; // 7 calories per gram of alcohol
            $alcoholElement.attr('data-calories', calories.toFixed(2));
            $alcoholElement.attr('data-size', size);

            // these rows added for showing servings in bac counter too
            let totalServings = servings * $(this).find('.amount').text();
            allServings += totalServings;
        });
        $('#total-servings').text(allServings.toFixed(1)); 
    }

})(jQuery);
